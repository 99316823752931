import React, { useState, useEffect, useContext } from 'react'
import Layout from '../components/Layout'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import queryString from 'query-string'
import Select from 'react-select'

import { Context } from '../Context'
import { isBrowser } from '../components/Auth'
import { getUsers, putUser } from '../services/api/users'
import { toast } from 'react-toastify'
import { getForces } from '../services/api/forces'

export default function AssignForcePage (props) {
  const { isAuth, loggedUser, setLoggedUser } = useContext(Context)

  if (isBrowser() && !isAuth) {
    if (isBrowser()) {
      window.location.href = '/'
    }

    return (
      <Layout withoutHeader className='page-users'>
        Cargando...
      </Layout>
    )
  }

  const [user, setUser] = useState({
    name: '',
    name2: '',
    lastname: ''
  })
  const [forces, setForces] = useState([])
  const params = queryString.parse(props.location.search)

  // get organigrams from backend
  useEffect(() => {
    getForces().then(res => {
      setForces(res.data.map(i => {
        return {
          value: i.id,
          label: i.name
        }
      }))
    }).catch(err => console.error(err))
  }, [])

  // carga el usuario
  useEffect(() => {
    if (loggedUser.id === params.user_id) {
      setUser(loggedUser)
      return
    }

    getUsers({ id: params.user_id })
      .then(res => {
        if (res.data.length > 0) {
          setUser(res.data[0])
        }
      })
      .catch(err => {
        console.error(err.response)
        toast.error(err.response.data.message)
      })
  }, [])

  const onSubmit = values => {
    const userBody = {
      ...user,
      force_id: values.force,
      organigram_id: 0,
      rank_id: 0
    }

    putUser({
      args: { action: 'update_names' },
      body: userBody
    }).then(() => {
      if (loggedUser.id === userBody.id) {
        setLoggedUser(userBody)
      }

      toast.success('Se ha asignado la fuerza al usuario correctamente')

      navigate('/users/')
    }).catch(err => {
      console.error(err.response)
      toast.error(err.response ? err.response.data.message : err)
    })
  }

  return (
    <Layout>
      <div
        style={{
          maxWidth: '500px',
          margin: '3rem auto',
          color: 'white'
        }}
      >
        <h2 style={{ color: 'white' }}>
          Fuerzas de {user.name} {user.name2} {user.lastname}
        </h2>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={{ force: user.force_id }}
          render={({ values, setFieldValue }) => (
            <Form>
              <label style={{ width: '100%' }}>
                <div>FUERZA</div>
                <div style={{ display: 'flex' }}>
                  <Select
                    options={forces}
                    onChange={option => setFieldValue(
                      'force', option.value
                    )}
                    value={forces.find(i => i.value === values.force)}
                    placeholder='Fuerza'
                    name='force'
                    className='select'
                    classNamePrefix='select'
                  />
                </div>
              </label>

              <div style={{ marginTop: '2rem' }}>
                <button
                  type='submit'
                  style={{
                    fontSize: '1rem',
                    backgroundColor: 'white',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '0.25rem 1rem',
                    color: 'var(--color2)',
                    fontWeight: 'bold'
                  }}
                >
                  GUARDAR
                </button>
              </div>
            </Form>
          )}
        />
      </div>
    </Layout>
  )
}
